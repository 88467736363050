import React from 'react';
import { Router } from 'react-router-dom';
import history from './utils/history';

import NavBarEmail from './components/NavBarEmail';
import FooterEmail from './components/FooterEmail';

const Email = (props) => {
  return (
        <>
            <Router history={history}>
                <NavBarEmail />
                <div style={{ margin: '5rem' }}>
                    <h1>Confirm Your Email Address</h1>
                    <br></br>
                    <h5 style={{ lineHeight: '1.9', fontWeight: 'inherit' }}>
                        We have sent an email with a confirmation link to your email address.
                        In order to complete the sign-up process, please click the confirmation link.
                    </h5>

                </div>
                <FooterEmail />
            </Router>
        </>
  );
};

Email.propTypes = {

};

export default Email;
