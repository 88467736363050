import React from 'react';
import PropTypes from 'prop-types';
import styles from './paginate.module.css';

const Pagination = ({
  licensesPerPage, totalLicenses, paginate, currentPage
}) => {
  const licenseNumbers = [];
  for (let i = 1; i <= Math.ceil(totalLicenses / licensesPerPage); i += 1) {
    licenseNumbers.push(i);
  }

  return (
        <nav>
            <ul style={{ display: 'flex', justifyContent: 'center', listStyleType: 'none' }}>
                {licenseNumbers.map((number) => {
                  return (
                    <li key={number}>
                        <div onClick={() => { return paginate(number); }}
                        style={{ backgroundColor: currentPage === number ? 'gainsboro' : 'white' }}
                        className={styles.pageLink}>
                            {number}
                        </div>
                    </li>
                  );
                })}
            </ul>
        </nav>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  paginate: PropTypes.func,
  totalLicenses: PropTypes.number,
  licensesPerPage: PropTypes.number
};

export default Pagination;
