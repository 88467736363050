import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loadLicense } from '../actions/license';
import loadingData from '../assets/loadingdata.gif';
import styles from './license.module.css';

const LicenseDetail = ({
  token, match, loadLicense, licensesdata
}) => {
  const { id } = match.params;

  const [result, setResult] = useState({});

  const [progress, setProgress] = useState({
    'Extra Call ': {},
    'Extra SMS ': {},
    'Extra Whatsapp ': {},
    'Call ': {},
    'SMS ': {},
    'Whatsapp ': {}
  });

  useEffect(() => {
    loadLicense(token);
  }, [loadLicense, token]);

  useEffect(() => {
    const dataFilter = licensesdata.find((obj) => {
      return obj.serial === id;
    });
    setResult(dataFilter);
    if (result) {
      setProgress({
        'Extra Call ': {
          counter: result.addCallCounter,
          limit: result.addCallLimit,
          progress: result.addCallCounter / result.addCallLimit
        },
        'Extra SMS ': {
          counter: result.addSmsCounter,
          limit: result.addSmsLimit,
          progress: result.addSmsCounter / result.addSmsLimit
        },
        'Extra Whatsapp ': {
          counter: result.addWhatsCounter,
          limit: result.addWhatsLimit,
          progress: result.addWhatsCounter / result.addWhatsLimit
        },
        'Call ': {
          counter: result.callCounter,
          limit: result.callLimit,
          progress: result.callCounter / result.callLimit
        },
        'SMS ': {
          counter: result.smsCounter,
          limit: result.smsLimit,
          progress: result.smsCounter / result.smsLimit
        },
        'Whatsapp ': {
          counter: result.whatsCounter,
          limit: result.whatsLimit,
          progress: result.whatsCounter / result.whatsLimit
        }
      });
    }
  }, [licensesdata, result]);

  return (
    result ? (<div>
            <h2 style={{ marginBottom: '1rem' }}>
                {result.serial}
            </h2>
            <div>
                <h6>Activation Date</h6>
                {result.activityTime}
            </div>
            <div>
                <h6>License Expiration</h6>
                {result.licenseExpires}
            </div>
            <div>
                <h6>Note</h6>
                {result.notes}
            </div>

            <div className={styles.usage}>
                <h3>Data Usage</h3>
            </div>

            <thead>
            <tr>
                <th >Item</th>
                <th style={{ width: '300px' }}>Usage</th>
                <th style={{ width: '150px' }}></th>
            </tr>
            </thead>
            <tbody>
                {Object.keys(progress).map((key) => {
                  return (<tr key={key} style={{ lineHeight: '40px' }}>
                        <th>{key}</th>
                        <th>
                            <div className={styles.progressGrey}>
                                <div className={styles.progressBlue}
                                style={{
                                  transition: '2s',
                                  width: Number.isNaN(progress[key].progress) ? '0%' : `${progress[key].progress * 100
                                  }%`
                                }}>
                                </div>
                            </div>
                        </th>
                        <th style={{ textAlign: 'center', color: '#b1b0b0', fontWeight: '300' }}>
                            Used{progress[key].counter} (Total {progress[key].limit})
                        </th>
                    </tr>);
                })}
            </tbody>
            <RouterNavLink
                    to="/license"
                    activeClassName="router-link-exact-active"
                    style={{ color: 'white' }}
                  >
                    <Button color="secondary" className="mt-5" style={{ float: 'right' }} >
                    Go Back
                    </Button>
            </RouterNavLink>
        </div>) : (
        <div style={{
          height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}><img alt="img" src={loadingData} style={{ width: '100px' }}/></div>)
  );
};

LicenseDetail.propTypes = {
  token: PropTypes.number,
  licensesdata: PropTypes.string,
  loadLicense: PropTypes.func,
  match: PropTypes.number
};

// map redux state to the component props
const mapStateToProps = (state) => {
  return {
    licensesdata: state.licensesdata.licensesdata,
    loading: state.loading.loading,
    token: state.token.token
  };
};

export default connect(mapStateToProps, { loadLicense })(LicenseDetail);
