const contentData = [
  {
    title: 'Simple and Fast',
    link: 'https://signaal.me/',
    description:
      'Send instant notifications via SMS and other social communications app'
  },
  {
    title: 'Anywhere and Anytime',
    link: 'https://signaal.me/',
    description:
      'You can receive instant alerts on your phone no matter where you are'
  },
  {
    title: 'Variety of functions',
    link: 'https://signaal.me/',
    description:
      'Signaal provides a variety of customized functions to meet enterprises needs'
  },
  {
    title: 'Strong Integration',
    link: 'https://signaal.me/',
    description:
      'Script allows enterprises to seamlessly integrate Whatsup Gold'
  }
];

export default contentData;
