import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import styles from './hero.module.css';

const Hero = () => {
  const { user } = useAuth0();
  const [hour, setHour] = useState(null);

  useEffect(() => {
    const gethour = () => {
      const date = new Date();
      const hours = date.getHours();
      setHour(hours);
    };

    gethour();
  }, []);

  return (<div className="my-5 mx-5">
  <h1><span aria-label="hand" role="img">👋</span> {hour < 12 ? 'Good Morning' : 'Good Aftrnoon'}, {user ? user.name : 'stranger'}</h1>
  <h4 className={styles.helperSlogan}>Here are your little helpers <div className={styles.bounce}><span aria-label="hand"  role="img">👇</span></div></h4>

  <div className={styles.dashHelper}>
      {/* <div className={`${styles.helperBox} ${styles.colorOne}`}>
          <div className="p-4">
          <h3 className={styles.height60}>LIVE SUPPORT CHAT</h3>
          <h5>Real-time messaging with ore support.</h5>
          </div>
      </div>  */}

      <div className={`${styles.helperBox} ${styles.colorTwo}`}>
          <div className="p-4">
          <RouterNavLink
            to="/license"
            style={{ color: 'white' }}
            >
          <h3 className={styles.height60}>LICENSE HISTORY</h3>
          <h5>Track and keep up-to-date with your license.</h5>
          </RouterNavLink>
          </div>
      </div>

      {/* <div className={`${styles.helperBox} ${styles.colorThree}`} >
          <div className="p-4">
          <h3 className={styles.height60}>Ticketing Form</h3>
          <h5>Report bug and submit issues with products.</h5>
          </div>
      </div>  */}
  </div>
</div>);
};

export default Hero;
