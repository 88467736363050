import React, { useState, useEffect } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../App.css';
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import LogoGreen from '../assets/signaalgreen.svg';

const NavBarEmail = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    logout,
    getAccessTokenSilently
  } = useAuth0();
  const toggle = () => { return setIsOpen(!isOpen); };
  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        audience: 'https://signaal.us.auth0.com/api/v2/'
      }).then((accessToken) => {
        localStorage.setItem('token', accessToken);
      });
    }
  });
  const logoutWithRedirect = () => {
    return logout({
      returnTo: window.location.origin
    });
  };

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md">
        <Container>
          <NavbarBrand >
            <img className="app-logo" src={LogoGreen} alt="React logo" width="45" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>

              {isAuthenticated && (
                <>
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/external-api"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Ticketing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/external-apitest"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Ticketing
                  </NavLink>
                </NavItem>
                  <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/license"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    License
                  </NavLink>
                </NavItem>
                </>
              )}
            </Nav>

            {isAuthenticated && (
              <Nav
                className="d-md-none justify-content-between"
                navbar
                style={{ minHeight: 170 }}
              >
                <NavItem>
                  <span className="user-info">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="50"
                    />
                    <h6 className="d-inline-block">{user.name}</h6>
                  </span>
                </NavItem>
                {/* <NavItem>
                  <FontAwesomeIcon icon="user" className="mr-3" />
                  <RouterNavLink
                    to="/profile"
                    activeClassName="router-link-exact-active"
                  >
                    Profile
                  </RouterNavLink>
                </NavItem> */}
                <NavItem>
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => { return logoutWithRedirect(); }}
                  >
                    Log out
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBarEmail;
