import React from 'react';
import Loader from '../assets/loading.gif';

const Loading = () => {
  return (
  <div style={{
    margin: 'auto',
    display: 'block',
    position: 'absolute',
    top: '50%',
    textAlign: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }}>
        <img
              src={Loader}
              style={{ width: '200px' }}
              alt='Loading...'
            />
            <h3 style={{ color: '#D0D0D0' }}>Redirecting...</h3>
        </div>
  );
};

export default Loading;
