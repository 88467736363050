import React from 'react';

const Footer = () => {
  return (
  <footer className="d-flex justify-content-center align-items-center bg-light p-3 ">
    <p>
      Help Desk by <a style={{ color: '#38C7BB' }} href="https://signaal.me/">Signaal.me</a>
    </p>
  </footer>
  );
};

export default Footer;
