import {
  GET_LICENSES,
  LICENSES_ERROR,
  GET_TOKEN
} from './type';

export const loadLicense = (token) => {
  return async (dispatch) => {
    try {
    // get jwt token
      const response = await fetch('https://dypu0qoa9c.execute-api.ap-northeast-1.amazonaws.com/prod/api/license', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const responseData = await response.json();
      dispatch({
        type: GET_LICENSES,
        payload: responseData.licenses
      });
    } catch (err) {
      dispatch({
        type: LICENSES_ERROR,
        payload: { msg: err, status: err }
      });
    }
  };
};

export const getToken = (token) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_TOKEN,
        payload: token
      });
    } catch (err) {
    // console.log('err response',err);
    }
  };
};
