import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { FaCheck } from 'react-icons/fa';

import contentData from '../utils/contentData';

class Content extends Component {
  render() {
    return (
      <div className="next-steps my-5">
        <h2>What can Signaal help you with?</h2>
        <Row className="d-flex justify-content-between">
          {contentData.map((col, i) => {
            return (
            <Col key={i} md={5} className="mb-4">
              <h6 className="mb-3">
                <a href={col.link} style={{ color: '#3DC7BE' }}>
                  <FaCheck className="mr-2" />
                  {col.title}
                </a>
              </h6>
              <p>{col.description}</p>
            </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

export default Content;
