import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import Loading from './components/Loading';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import ExternalApi from "./views/ExternalAPI";
import Home from './views/Home';
import Profile from './views/Profile';
import License from './views/License';
import LicenseDetail from './views/LicenseDetail';
import NotFound from './views/NotFound';
import Email from './Email';
import history from './utils/history';
// Redux
import store from './store';
// import { loadUser } from './actions/auth';
// import setAuthToken from './utils/setAuthToken';
// styles
import './App.css';
// fontawesome
import initFontAwesome from './utils/initFontAwesome';

initFontAwesome();

const getUrlParameter = (key) => {
  const searchStr = window.location.search;
  const json = {};
  searchStr.substr(1).split('&').forEach((str) => {
    const pair = str.split('=');
    json[pair[0]] = pair[1];
  });
  return json[key] || '';
};

const App = () => {
  const { isLoading, error } = useAuth0();
  if (getUrlParameter('success') !== 'true' && error) {
    console.log('error2', error);
    if (error.message === 'Please verify your email before logging in.') {
      return <Email/>;
    }
    return <div>Oops... {error.message}</div>;
  }

  // if (error) {
  //   return <div>Oops... {error.message}</div>;
  // }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Provider store={store}>
      <Router history={history}>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <Container className="flex-grow-1 mt-5">
            <Switch>
              <Route path="/" exact component={Home} />
              {/* <Route path="/external-apitest" component={ExternalApi} /> */}
              <Route path="/profile" component={Profile} />
              <Route path="/license" exact component={License} />
              <Route path="/email" exact component={Email } />
              <Route path="/license/:id" component={LicenseDetail} />
              <Route component={NotFound} />
            </Switch>
          </Container>
          <Footer />
        </div>
      </Router>
    </Provider>
  );
};
export default App;
