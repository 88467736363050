import React, { useState } from 'react';
import {
  FormFeedback, Button, Container, Row, Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../components/Loading';
import styles from './profile.module.css';
import '../App.css';

export const ProfileComponent = () => {
  const { user } = useAuth0();
  const [profile, setProfile] = useState({
    phone: '',
    company: ''
  });
  const [customStyle, setCustomStyle] = useState({
    displayphone: '',
    displaycompany: ''
  });

  const onSubmit = (e) => {
    e.preventDefault();

    if (!profile.phone && !profile.company) {
      setCustomStyle({ displayphone: 'block', displaycompany: 'block' });
    }
  };

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>Hello, {user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      {/* <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Row> */}
      <Form className={styles.form}>
        <FormGroup>
          <Label for="name">Name</Label>
          <Input type="name" name="name" id="name" value={user.name}/>
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input type="text" name="email" id="email" value={user.email}/>
        </FormGroup>
        <FormGroup>
          <Label for="phone">Phone</Label>
          <Input type="text" name="phone" id="phone"
          onChange={ (e) => {
            setProfile({
              ...profile,
              phone: e.target.value
            });
          }}
          value={profile.phone}/>
          <FormFeedback style={{ display: `${customStyle.displayphone}` }}>Please fill in your phone.</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="company">Company</Label>
          <div className={styles.tooltip}>
            <BsQuestionCircleFill/>
            <span className={styles.tooltiptext}>Let us know more about where you work at</span>
          </div>
          <Input type="text" name="company" id="company"
          onChange = { (e) => {
            setProfile({
              ...profile,
              company: e.target.value
            });
          }}
          />
          <FormFeedback style={{ display: `${customStyle.displaycompany}` }}>Please fill in the company name.</FormFeedback>
        </FormGroup>
        <Button
          onClick = { (e) => { onSubmit(e); }}
          className="btn-primary"
        >
          Submit
        </Button>

      </Form>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => { return <Loading />; }
});
