import React, { useState, useEffect, useMemo } from 'react';
import { Table } from 'reactstrap';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BsArrowUp } from 'react-icons/bs';
import { connect } from 'react-redux';
import styles from './license.module.css';
import Pagination from '../components/Pagination';
import { loadLicense } from '../actions/license';
import '../App.css';
import loadingData from '../assets/loadingdata.gif';

const License = ({ loadLicense, licensesdata, token }) => {
  const [filter, setFilter] = useState('');
  const [licenses, setLicenses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [licensesPerPage] = useState(5);

  useEffect(() => {
    loadLicense(token);
  }, [loadLicense, token]);

  useEffect(() => {
    const lowercasedFilter = filter.toLowerCase();

    if (licensesdata) {
      const filteredData = licensesdata.filter((item) => {
        return Object.keys(item).some((key) => {
          return item[key].toString().toLowerCase().includes(lowercasedFilter);
        });
      });
      setLicenses(filteredData);
      if (filteredData.length <= 5) {
        setCurrentPage(1);
      }
    }

    // put filtered data back to license state
  }, [filter, licensesdata]);

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  // get current licenses
  const indexOfLastLicense = currentPage * licensesPerPage;
  const indexOfFirstLicense = indexOfLastLicense - licensesPerPage;
  const currentLicenses = licenses.slice(indexOfFirstLicense, indexOfLastLicense);

  // change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // sorting function
  const [sortConfig, setSortConfig] = useState(null);
  const [rotate, setRotate] = useState(false);

  const sortedItems = useMemo(() => {
    const sortedProducts = [...licenses];
    if (sortConfig !== null) {
      sortedProducts.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    setLicenses(sortedProducts);
    return sortedProducts;
  }, [sortConfig]);

  const requestSort = (key) => {
    if (rotate) {
      setRotate(false);
    } else {
      setRotate(true);
    }

    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    // go to useeffect, to set the sortConfig
    setSortConfig({ key, direction });
  };
  // class name for rotate arrow
  const getClassNamesFor = (name) => {
    if (sortConfig) {
      return sortConfig.key === name ? sortConfig.direction : undefined;
    }
  };

  return (
        <>
        <h1>License</h1>
        {licensesdata.length > 0 ? (<><div className={styles.search} >
            <input placeholder="Search..." className={styles.licenseSearch} value={filter} onChange={handleFilter}/>
            {/* <FcSearch className={styles.icon}/> */}
        </div>
        <Table hover bordered className="mt-4">
            <thead>
            <tr>
                <th>Serial</th>
                <th
                    style={{ cursor: 'pointer' }}
                    onClick={() => { return requestSort('activityTime'); }}>
                    Activate Time
                    <BsArrowUp className={`rotate ${getClassNamesFor('activityTime')}`} />
                </th>
                <th
                    style={{ cursor: 'pointer' }}
                    onClick={() => { return requestSort('licenseExpires'); }}>
                    License Expire
                    <BsArrowUp className={`rotate ${getClassNamesFor('licenseExpires')}`} />
                </th>
                <th
                    style={{ cursor: 'pointer' }}
                    onClick={() => { return requestSort('serviceAgreement'); }}>
                    Service Agreement
                    <BsArrowUp className={`rotate ${getClassNamesFor('serviceAgreement')}`}/>
                </th>
                <th>Detail</th>
            </tr>
            </thead>
            <tbody>
            {currentLicenses.map((license, i) => {
              return (
            <>
            <tr key={i}>
                <th scope="row">{license.serial}</th>
                <td>{license.activityTime}</td>
                <td>{license.licenseExpires}</td>
                <td>{license.serviceAgreement}</td>
                <td>
                    <Link className="view" to={`/license/${license.serial}`}>
                        View
                    </Link>
                </td>
            </tr>
            </>);
            })
            }
            </tbody>
        </Table>
        <Pagination
        licensesPerPage={licensesPerPage}
        totalLicenses={licenses.length}
        paginate={paginate}
        currentPage={currentPage}
        />
        </>) : (<div style={{
          height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}><img alt="img" src={loadingData} style={{ width: '100px' }}/></div>)}
        </>
  );
};

License.propTypes = {
  token: PropTypes.number,
  licensesdata: PropTypes.string,
  loadLicense: PropTypes.func
};

// map redux state to the component props
const mapStateToProps = (state) => {
  return {
    licensesdata: state.licensesdata.licensesdata,
    token: state.token.token
  };
};

export default withAuthenticationRequired(connect(mapStateToProps, { loadLicense })(License), {
  onRedirecting: () => <Loading />,
});
