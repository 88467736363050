import {
  GET_LICENSES,
  LICENSES_ERROR,
  TOGGLE_LOAD,
  GET_TOKEN
} from '../actions/type';

const initialState = {
  licensesdata: [],
  licenseid: {},
  token: '',
  loading: true
};

// reducer is a function that takes two parameter
// reducer is immutable and always returns a copy of entire state
export default function licenseReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_LOAD:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_LICENSES:
      return {
        ...state,
        licensesdata: payload
      };
    case GET_TOKEN:
      return {
        ...state,
        token: payload
      };
    case LICENSES_ERROR:
      return {
        ...state,
        error: payload
      };
    default:
      return state;
  }
}
