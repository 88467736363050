import React from 'react';

const NotFound = () => {
  return (
        <div>
            <h1 style={{ marginBottom: '2rem' }}>Page Not Found</h1>
            <h4 style={{ color: '#6d6d6d', marginBottom: '1rem' }}>
              We could not find what you were looking for.
            </h4>
            <p>
              Please contact the owner of the site that linked you to the original URL
              and let them know their link is broken.
            </p>
        </div>
  );
};

export default NotFound;
