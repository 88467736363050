import { combineReducers } from 'redux';
import licenseReducer from './license';

const rooReducer = combineReducers({
  licensesdata: licenseReducer,
  licenseIdData: licenseReducer,
  loading: licenseReducer,
  token: licenseReducer
});

export default rooReducer;
